<template>
  <div class="container mb-4">
    <!-- {{ subscription }} -->
    <div class="columns mt-4">
      <div class="column is-4">
        <article class="panel is-secondary">
          <p class="panel-heading">
            Starter {{ subscription.key == "free-trial" ? "(Current)" : "" }}
          </p>
          <p class="m-3">
            Free trial package for anyone who wish to join and experience our
            platform.
          </p>
          <div class="panel-block">
            <p class="title is-4 mb-2">FREE</p>
          </div>
          <div class="panel-block">
            <span class="panel-icon">
              <i class="fas fa-check" aria-hidden="true"></i>
            </span>
            Job posts on our website
          </div>
          <div class="panel-block">
            <span class="panel-icon">
              <i class="fas fa-check" aria-hidden="true"></i>
            </span>
            Repost in our social media
          </div>
          <div class="panel-block">
            <span class="panel-icon">
              <i class="fas fa-check" aria-hidden="true"></i>
            </span>
            Statistics and profile visits
          </div>
          <div class="panel-block">
            <span class="panel-icon">
              <i class="fas fa-check" aria-hidden="true"></i>
            </span>
            Valid for 30 days
          </div>
          <div class="panel-block">
            <button class="button is-disabled is-fullwidth" disabled>
              Already used
            </button>
          </div>
        </article>
      </div>
      <div class="column is-4">
        <article class="panel is-primary">
          <p class="panel-heading">
            Partner
            {{
              subscription.plan == "annual" && subscription.is_active == true
                ? "(Current)"
                : ""
            }}
          </p>
          <p class="m-3">
            Recommended for frequent hirers who post more than 6 jobs within a
            year.
          </p>
          <div class="panel-block">
            <p class="title is-4 mb-2" v-if="annualPlan">
              MVR
              {{
                annualPlan.deal_price
                  ? formatNumber(annualPlan.deal_price)
                  : formatNumber(annualPlan.price)
              }}/ Year
              <b-tag type="is-info" class="mb-1" v-if="annualPlan.deal_price"
                >Deal price</b-tag
              >
            </p>
            <p class="title is-4 mb-2" v-else>MVR ......../Year</p>
          </div>

          <div class="panel-block">
            <span class="panel-icon">
              <i class="fas fa-check" aria-hidden="true"></i>
            </span>
            Unlimited job posts
          </div>
          <div class="panel-block">
            <span class="panel-icon">
              <i class="fas fa-check" aria-hidden="true"></i>
            </span>
            Repost in our social media
          </div>
          <div class="panel-block">
            <span class="panel-icon">
              <i class="fas fa-check" aria-hidden="true"></i>
            </span>
            Statistics and profile visits
          </div>
          <div class="panel-block">
            <span class="panel-icon">
              <i class="fas fa-check" aria-hidden="true"></i>
            </span>
            Valid for 1 year
          </div>
          <div class="panel-block">
            <button
              class="button is-primary is-light is-fullwidth"
              v-if="
                subscription.plan == 'annual' && subscription.is_active == true
              "
            >
              Currently active
            </button>
            <button
              @click.prevent="upgradeSubscription(annualPlan.id)"
              class="button is-primary is-outlined is-fullwidth"
              v-else
            >
              Subscribe now
            </button>
          </div>
        </article>
      </div>
      <div class="column is-4">
        <article class="panel is-secondary">
          <p class="panel-heading">
            Basic
            {{
              subscription.plan == "per-job" && subscription.is_active == true
                ? "(Current)"
                : ""
            }}
          </p>
          <p class="m-3">
            Recommended for one-time hirers who wish to advertise on our
            platform.
          </p>
          <div class="panel-block">
            <p class="title is-4 mb-2" v-if="perJobPlan">
              MVR
              {{
                perJobPlan.deal_price
                  ? formatNumber(perJobPlan.deal_price)
                  : formatNumber(perJobPlan.price)
              }}/ Job
              <b-tag type="is-info" class="mb-1" v-if="perJobPlan.deal_price"
                >Deal price</b-tag
              >
            </p>
            <p class="title is-4 mb-2" v-else>MVR ...../Job</p>
          </div>
          <div class="panel-block">
            <span class="panel-icon">
              <i class="fas fa-check" aria-hidden="true"></i>
            </span>
            Unlimited job posts (billed per job)
          </div>
          <div class="panel-block">
            <span class="panel-icon">
              <i class="fas fa-check" aria-hidden="true"></i>
            </span>
            Repost in our social media
          </div>
          <div class="panel-block">
            <span class="panel-icon">
              <i class="fas fa-check" aria-hidden="true"></i>
            </span>
            Statistics and profile visits
          </div>
          <div class="panel-block">
            <span class="panel-icon">
              <i class="fas fa-check" aria-hidden="true"></i>
            </span>
            Valid for 1 year
          </div>
          <div
            class="panel-block"
            v-if="subscription.plan == 'annual' && subscription.is_active"
          >
            <button class="button is-disabled is-fullwidth" disabled>
              Downgrade not available
            </button>
          </div>
          <div class="panel-block" v-else>
            <button
              class="button is-success is-light is-fullwidth"
              v-if="
                subscription.plan == 'per-job' && subscription.is_active == true
              "
            >
              Currently active
            </button>
            <button
              v-else
              class="button is-primary is-outlined is-fullwidth"
              @click.prevent="upgradeSubscription(perJobPlan.id)"
            >
              Subscribe now
            </button>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  components: {
    //
  },
  data() {
    return {
      plans: [],
      subscription: {},
    };
  },
  computed: {
    getEmployer() {
      return this.$store.getters["employers/getEmployer"];
    },
    // curPlan() {
    //   return this.getEmployer.current_subscription.plan;
    // },
    annualPlan() {
      return this.plans.find((plan) => plan.key == "annual");
    },
    perJobPlan() {
      return this.plans.find((plan) => plan.key == "per-job");
    },
  },
  methods: {
    async getPlans() {
      try {
        const response = await axios.get(
          `api/plans/?emp=${this.getEmployer.id}`
        );
        this.plans = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getSubscription() {
      try {
        const response = await axios.get(
          `api/employers/${this.getEmployer.id}/subscription/`
        );
        this.subscription = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    formatNumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    submitFreetrial() {
      this.$buefy.toast.open({
        message: "Free trial has already been utilized.",
        type: "is-danger",
        queue: false,
        duration: 3000,
      });
    },
    async upgradeSubscription(id) {
      try {
        await axios.post(`api/employers/${this.getEmployer.id}/upgrade/`, {
          plan_id: id,
        });
        this.$buefy.toast.open({
          message: "Subscription successful. You can now proceed to post jobs.",
          type: "is-success",
          queue: false,
          duration: 3000,
        });
        // get subscription again
        this.getSubscription();
        // update employer
        this.$store.dispatch("employers/updateEmployer", this.getEmployer.id);
      } catch (error) {
        this.$buefy.toast.open({
          message: "Something went wrong. Please try again.",
          type: "is-danger",
          queue: false,
          duration: 3000,
        });
        console.log(error);
      }
    },
  },
  created() {
    this.getSubscription().then(() => {
      this.getPlans();
    });
  },
};
</script>

<style scoped>
.no-shadow {
  box-shadow: none !important;
}

.side-bar {
  position: relative;
}

.sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 5rem;
}

.red {
  border: 1px solid red;
}

.blue {
  border: 1px solid blue;
}

.grey-text {
  color: #4a4a4a;
}

.grey-text:hover {
  color: #485fc7;
}

.bold {
  font-weight: 600;
}
</style>
